<template>
  <div class="pageTitle">
    <div v-if="titleVisible">{{ title }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    titleVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.pageTitle {
  border-bottom: 1px solid #eee;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  padding: 10px 0px;
  margin-bottom: 20px;
}
</style>
