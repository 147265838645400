/* eslint-disable no-unused-vars */
import Vue from "vue";
import Antd from "ant-design-vue";
import Modal from "ant-design-vue/es/modal";
import message from "ant-design-vue/es/message";
// import { default as Pagination } from "ant-design-vue/es/pagination";
import { default as Table } from "ant-design-vue/es/table";
Vue.prototype.$message = message;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
/* v1.1.3+ registration methods */
Vue.use(Antd);

/** 重写Table翻页行为 */
{
  const expired = Table.methods.updateColumns;
  Table.methods.updateColumns = function () {
    const arg = Array.prototype.splice.call(arguments, 0);
    if (
      this.pagination &&
      this.dataSource.length === 0 &&
      this.pagination.total > 0 &&
      this.pagination.current > 1 &&
      !this.pagination.lastUpdated
    ) {
      this.pagination.current--;
      this.pagination.lastUpdated = true;
      const self = this;
      setTimeout(() => {
        self.pagination.lastUpdated = false;
      }, 1000);
      this.$emit("change", this.pagination);
    }
    return expired.apply(this, arg);
  };
}
