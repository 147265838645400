/* eslint-disable no-unused-vars */
import config from "@/config";
import store from "@/store";
const { getters } = store;
const apiConfig = config.api;
const signalR = require("@microsoft/signalr");
// @abp/signalr 不知道如何使用
// const signalR = require('@abp/signalr')
// import * as signalR from '@abp/signalr'
// aspnet/signalr 是microsoft/signalr 的旧版本

const getHttpConnectionOptions = function (api) {
  // 无法从 @microsoft\signalr 导出 IHttpConnectionOptions,此处模拟之
  const options = {};
  // 指定使用WebSockect,目前只配了ws的路由转发
  options.transport = signalR.HttpTransportType.WebSockets;
  // 跳过握手
  options.skipNegotiation = false;
  // 读取token
  // options.accessTokenFactory = () => token.access_token
  //     options.logger = new ConsoleLogger()
  //     options.httpClient = new signalR.DefaultHttpClient(options.logger)
  //    const   options.httpClient.httpClient.getCookieString
  // const headers = options.headers = {}
  // 赋值给headers 则只会在握手时生效，websockect 正式连接只会使用cookie
  return options;
};
function makeAuthorizationUrl(url) {
  const token = getters.getToken;
  const tenant = getters.getTenant;
  const language = getters.getCurrentLanguageCode;
  const getUserInfo = getters.getUserInfo;
  if (url.indexOf("?") < 0) {
    url = url + "?";
  } else {
    url = url + "&";
  }
  const auth = token; // `${token.token_type} ${token.access_token}`
  const authUrl = encodeURIComponent(auth);
  const tenantId = tenant.tenantId || tenant.id;
  const tenantUrl = encodeURIComponent(tenantId);
  // const companyId = getUserInfo.companyId;
  // const companyUrl = encodeURIComponent(companyId);
  const lan = language;
  const lanUrl = encodeURIComponent(lan);
  const sessionId = getters.getSessionId;
  const sessionUrl = encodeURIComponent(sessionId);
  if (token && token != "1") {
    url = `${url}access_token=Bearer ${authUrl}`;
  } else {
    url = `${url}&1=1`;
  }
  url += `&__tenant=${tenantUrl}&Accept-Languag=${lanUrl}&c_s=${sessionUrl}`;
  // url = `http://localhost:63963/hubs/notification?access_token=${authUrl}&__tenant=${tenUrl}&Accept-Languag=${lanUrl}`
  return url;
}
export default class HubBase {
  constructor(hubName, routerPath, rootUrl) {
    const self = this;
    this.name = hubName;
    this.routerPath = routerPath;
    if (typeof rootUrl !== "string") rootUrl = apiConfig.baseUrl;
    /**
     * 第一步 初始化
     */
    this.init = function () {
      this.api = makeAuthorizationUrl(`${rootUrl}${routerPath}`);
      // .replace(/http/i, 'ws')
      const options = getHttpConnectionOptions(this.api);
      let builder = new signalR.HubConnectionBuilder();
      builder = builder.withUrl(this.api, options);
      builder = builder.configureLogging(signalR.LogLevel.Information);
      // 自动重连
      builder = builder.withAutomaticReconnect();
      this.hubConnectionBuilder = builder;
      this.hub = this.hubConnectionBuilder.build();
      this.afterInit();
    };

    this.afterInit = function () {};

    /**
     * 第三步 开始工作
     */
    this.start = function () {
      let resolveSome;
      let rejectSome;
      const p = new Promise((resolve, reject) => {
        resolveSome = resolve;
        rejectSome = reject;
      });
      this.hub
        .start()
        .then(function () {
          console.log(`${hubName} connected!`);
          self.triggerChange();
          resolveSome();
        })
        .catch(function (err) {
          console.error(err.toString());
          rejectSome();
        });
      return p;
    };

    /**
     * 第二步 监听事件
     * @param {String} actionName 监听行为名称
     * @param {Function} handler 处理
     */
    this.on = function (actionName, handler) {
      this.hub.on(actionName, handler);
    };

    this.eventChangeStore = [];
    this.onChange = function (handler) {
      this.eventChangeStore.push(handler);
    };
    this.triggerChange = function () {
      const array = self.eventChangeStore;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        try {
          element();
        } catch (e) {
          console.log(e);
        }
      }
    };
  }
}
