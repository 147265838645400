/* eslint-disable no-unused-vars */
import { getUserInfoAsync, logoutAsync } from "@/api/account";

const account = {
  state: {
    user_info: {},
    loading: false,
    c_s: "",
  },

  mutations: {
    SET_TOKEN: (state, { accessToken, access_token }) => {
      // 应纯粹的修改token，如果需要变更用户信息，应再调用 SET_USER_INFO
    },
    SET_USER_INFO: (state, info) => {
      state.user_info = info;
    },
    SET_ACCOUNT_LOADING: (state, loading) => {
      state.loading = loading;
    },
    SET_PERSONAL: (state, info) => {
      Object.assign(state.user_info, info);
    },
    SET_SESSION_ID: (state, info) => {
      state.c_s = info;
    },
  },
  actions: {
    loadUserInfo: ({ commit }) => {
      return getUserInfoAsync().then((res) => {
        commit("SET_USER_INFO", res);
        return res;
      });
    },
    logout: ({ commit, rootState, dispatch }, autoRedirect) => {
      commit("SET_USER_INFO", {});
      dispatch("oidcStore/signOutOidc", {}, { root: true });
    },
  },
  getters: {
    getAccountLoading: (state) => {
      return state.loading;
    },
    getSessionId: (state) => {
      return state.c_s;
    },
    getUserInfo(state) {
      return state.user_info;
    },
    getTenant(state) {
      const user_info = state.user_info || {};
      return {
        id: user_info.tenantId,
        name: user_info.companyName,
      };
    },
  },
};

export default account;
