/* eslint-disable no-unused-vars */
// 内部转发SSO请求
import axios from "axios";
import store from "@/store";
import config from "@/config";
import qs from "qs";
import notification from "ant-design-vue/es/notification";
const { oidc } = config;
const request = axios.create({
  // baseURL: `/oidc`,
  baseURL: config.api.baseUrl,
});
const errorHandler = (error) => {
  const errorRes = error.response;
  if (errorRes) {
    // 从 localstorage 获取 token
    let token = store.getters.getToken;
    if (token && token.access_token) {
      token = token.access_token;
    }
    // if (errorRes.headers._abperrorformat && errorRes.status === 401) {
    //   store.dispatch("logout").then(() => {
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 1500);
    //   });
    // }
    const err = getError(errorRes.data.error || {}, errorRes.status);
    notification.error({
      message: err.title,
      description: err.message,
    });
  } else {
    notification.error({
      message: (error || "An unexpected error has occurred").toString(),
    });
  }

  return Promise.reject(error);
};
function getError(error = {}, status) {
  let message = "";
  let title = "发生错误";
  if (typeof error === "string") {
    message = error;
  } else if (error.details) {
    message = error.details;
    title = error.message;
  } else if (error.message) {
    message = error.message;
  } else {
    switch (status) {
      case 401:
        title = "未登录";
        message = "您还没有登录到系统";
        break;
      case 403:
        title = "未授权";
        message = "您还没有对应的操作权限";
        break;
      case 404:
        title = "未找到";
        message = "资源不存在";
        break;
      case 500:
        title = "内部错误";
        message = "系统发生了内部错误";
        break;
      default:
        break;
    }
  }
  return { title, message };
}
// request interceptor
request.interceptors.request.use(async (request) => {
  if (!request.headers["Content-Type"]) {
    request.headers["Content-Type"] = "application/json";
  }
  const language = store.getters.getCurrentLanguageCode;
  if (!request.headers["Accept-Language"] && language) {
    request.headers["Accept-Language"] = language;
  }
  const sessionId = store.getters.getSessionId;
  if (!request.headers["c_s"] && sessionId) {
    request.headers["c_s"] = sessionId;
  }
  return request;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  // {config={},data={},status=200,headers={},request={},statusText='OK'}
  if (response.headers) {
    if (response.headers["c_s"]) {
      store.commit("SET_SESSION_ID", response.headers["c_s"]);
    }
  }
  return response.data;
}, errorHandler);

export const oidcRequest = (
  method,
  url,
  config = { params: {}, headers: {}, data: {} }
) => {
  return new Promise((resolve, reject) => {
    request({
      method: method,
      url,
      ...config,
      paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
      },
    })
      .then((res) => {
        store.commit("SET_ACCOUNT_LOADING", false);
        resolve(res);
        return res;
      })
      .catch((err) => {
        store.commit("SET_ACCOUNT_LOADING", false);
        // console.error(err);
        resolve(err);
      });
  });
};

export const oidcRequestWithToken = (
  method,
  url,
  config = { params: {}, headers: {}, data: {} }
) => {
  const token = store.getters.getToken;
  if (!config.headers) config.headers = {};
  config.headers["Authorization"] = `Bearer ${token}`;
  return new Promise((resolve, reject) => {
    request({
      method: method,
      url,
      ...config,
      paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
      },
    })
      .then((res) => {
        store.commit("SET_ACCOUNT_LOADING", false);
        resolve(res);
      })
      .catch((err) => {
        store.commit("SET_ACCOUNT_LOADING", false);
        console.error(err);
        reject(err);
      });
  });
};

export function loginAsync(
  params = {
    UserName: "",
    Password: "",
    RememberMe: false,
    VerifyCode: "",
  }
) {
  store.commit("SET_ACCOUNT_LOADING", true);
  return new Promise((resolve, reject) => {
    oidcRequest("post", "api/identity/account/login", {
      data: { ...params, pictureId: sessionStorage.getItem("pictureId") },
    })
      .then((response) => {
        if (response && response.successed) {
          store.commit("SET_TOKEN", { accessToken: response.accessToken });
          resolve(response);
        } else {
          reject(`登陆失败：${response.errors.join(",")}!`);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function logoutAsync() {
  return oidcRequestWithToken("post", "api/identity/account/logout")
    .then((res) => {
      store.commit("SET_TOKEN", {});
      store.commit("SET_USER_INFO", {});
    })
    .catch((res) => {
      store.commit("SET_TOKEN", {});
      store.commit("SET_USER_INFO", {});
    });
}

export function sendMobileCodeAsync(
  params = {
    phoneNumber: "",
  }
) {
  return new Promise((resovle, reject) => {
    oidcRequest("post", "api/identity/account/sendPhoneVerifyCode", {
      data: { ...params, pictureId: sessionStorage.getItem("pictureId") },
    })
      .then((res) => {
        const result = res.result || res;
        if (result.success || result.successed) {
          resovle(result);
        } else {
          if (typeof result.errors !== "undefined") {
            reject(result.errors.join());
          } else {
            reject(result.message || "请输入正确的图形验证码!");
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getVerifyCodeAsync(params = { _h: false }) {
  return oidcRequest("get", "api/identity/account/pictureVerifyCode", {
    params: {
      dt: new Date().valueOf(),
      ...params,
    },
  }).then((data) => {
    sessionStorage.setItem("pictureId", data.id);
    return data;
  });
}
export function registerAsync(
  params = {
    username: "",
    password: "",
    verificationCode: "",
  }
) {
  store.commit("SET_ACCOUNT_LOADING", true);
  return new Promise((resolve, reject) => {
    oidcRequest("post", "api/identity/account/register", {
      data: { ...params, pictureId: sessionStorage.getItem("pictureId") },
    }).then((response) => {
      if (response && response.successed) {
        store.commit("SET_TOKEN", { accessToken: response.accessToken });
        resolve(response);
      } else {
        store.commit("SET_ACCOUNT_LOADING", false);
        reject(`注册失败:${response.errors.join(",")}!`);
      }
    });
  });
}
export function resetPasswordAsync(
  params = {
    username: "",
    phoneNumber: "",
    password: "",
    verifyCode: "",
  }
) {
  store.commit("SET_ACCOUNT_LOADING", true);
  return new Promise((resolve, reject) => {
    oidcRequest("post", "api/identity/account/resetPassword", {
      data: { ...params, pictureId: sessionStorage.getItem("pictureId") },
    }).then((response) => {
      if (response && response.successed) {
        store.commit("SET_TOKEN", { accessToken: response.accessToken });
        resolve();
      } else {
        store.commit("SET_ACCOUNT_LOADING", false);
        reject(`${response.errors.join(",")}!`);
      }
    });
  });
}

export function getUserInfoAsync() {
  const token = store.getters.getToken;
  store.commit("SET_ACCOUNT_LOADING", true);
  return oidcRequest("post", "api/identity/User/InitUserHomeData", {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      store.commit("SET_ACCOUNT_LOADING", false);
      return {
        ...response,
        ...response.userInfo,
        companyId:
          response.currentCompany !== null ? response.currentCompany.id : null,
        companyName:
          response.currentCompany !== null
            ? response.currentCompany.name
            : null,
        tenantId:
          response.currentCompany !== null
            ? response.currentCompany.tenantId
            : null,
      };
    })
    .catch(() => {
      store.commit("SET_ACCOUNT_LOADING", false);
    });
}

/**
 * 公司切换
 * @param {object} params 公司信息
 * @returns Promise
 */
export function switchCompanyAsync(params) {
  return oidcRequestWithToken("post", "api/identity/company/switchCompany", {
    data: {
      tenantId: params.tenantId,
    },
  }).then((response) => {
    const userInfo = store.getters.getUserInfo;
    userInfo.companyId = params.id;
    userInfo.companyName = params.name;
    userInfo.currentCompany = userInfo.companyList.find(
      (x) => x.id === params.id
    );
    userInfo.tenantId = params.tenantId;
    userInfo.permissions = response.permissions;
    userInfo.roles = response.roles;
    userInfo.isCompanyAdmin = response.isCompanyAdmin;
    store.commit("SET_USER_INFO", userInfo);
    setTimeout(() => {
      // 切回首页，因为某些页面，例如项目任务详情是跟公司息息相关的
      location.href = "/";
    }, 1000);
    return response;
  });
}

export function checkAccountAsync(
  params = {
    phoneNumber: "",
  }
) {
  return oidcRequest("get", "/api/identity/account/checkAccount", {
    params: {
      PhoneNumber: params.phoneNumber,
    },
  });
}
