// 产品管理路由
const productionRoutes = [
  {
    path: "FigureDocManage",
    component: { render: (e) => e("router-view") },
    redirect: "/FigureDocManage/index",
    name: "FigureDocManage",
    meta: {
      title: "图文档管理",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/FigureDocManage/Index.vue"),
        name: "FigureDocManageIndex",
        meta: {
          title: "图文档管理",
        },
      },
      {
        //path: "detail/:edit/:isFrom/:figureDocumentId",
        path: "detail/:edit/:figureDocumentId/:id",
        component: () => import("@/views/FigureDocManage/Detail.vue"),
        name: "FigureDocManageDetail",
        meta: {
          title: "图文档详情",
        },
        props: true,
      },
      {
        path: "detail/:isFrom/:figureDocumentId/:id",
        component: () => import("@/views/FigureDocManage/Detail.vue"),
        name: "FigureDocManageDetailHis",
        meta: {
          title: "图文档详情",
        },
        props: true,
      },
      {
        path: "compare/:currentVersionId/:historyVersionId",
        component: () => import("@/views/FigureDocManage/FigureDocCompare.vue"),
        name: "FigureDocManageCompare",
        meta: {
          title: "图文档比较",
        },
        props: true,
      },
      {
        path: "log/:figureDocumentId/:id/:edit/:isFrom",
        component: () => import("@/views/FigureDocManage/FigureDocLog.vue"),
        name: "FigureDocManageLog",
        meta: {
          title: "图文档日志",
        },
        props: true,
      },
    ],
  },
  {
    path: "MaterialManagement",
    name: "MaterialManagement",
    component: { render: (e) => e("router-view") },
    redirect: "/MaterialManagement/index",
    meta: {
      title: "物料管理",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/MaterialManagement/index.vue"),
        name: "MaterialManagementIndex",
        meta: {
          title: "物料管理",
        },
      },
      {
        path: "detail/:itemId",
        component: () => import("@/views/MaterialManagement/detail.vue"),
        name: "MaterialManagementDetail",
        meta: {
          title: "物料详情",
        },
        props: true,
      },
      {
        path: "edit/:itemId",
        component: () => import("@/views/MaterialManagement/detail.vue"),
        name: "MaterialManagementEdit",
        meta: {
          title: "编辑物料",
        },
        props: ({ params, query }) => {
          return {
            itemId: params.itemId || query.itemId,
            isEdit: true,
          };
        },
      },
      {
        path: "new/:id",
        component: () => import("@/views/MaterialManagement/new.vue"),
        name: "MaterialManagementNew",
        meta: {
          title: "物料新增",
        },
      },
    ],
  },
  {
    path: "/BOMManage",
    component: () => import("@/views/BOMManage/Index.vue"),
    name: "BOMManage",
    meta: {
      title: "BOM管理",
    },
  },
];

export default productionRoutes;
