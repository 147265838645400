import OidcCallback from "@/views/oidc/Callback.vue";
import OidcPopupCallback from "@/views/oidc/PopupCallback.vue";
import OidcCallbackError from "@/views/oidc/CallbackError.vue";
import OidcRenew from "@/views/oidc/Renew.vue";
let oidc = [
  {
    path: "/oidc-signin", // Needs to match redirectUri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
  },
  {
    path: "/oidc-signout", // Needs to match popupRedirectUri in you oidcSettings
    name: "oidcPopupCallback",
    component: OidcPopupCallback,
  },
  {
    path: "/oidc-callback-error", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallbackError",
    component: OidcCallbackError,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/oidc-silent-renew", // Needs to match redirect_uri in you oidcSettings
    name: "oidcSilentRenew",
    component: OidcRenew,
  },
];
export default oidc;
