<template>
  <a-card>
    <div style="text-align: center">
      <h1>您已退出智能硬件平台!</h1>
      <a-spin :spinnig="true" />
    </div>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "OidcPopupCallback",
  methods: {
    ...mapActions("oidcStore", ["oidcSignInPopupCallback"]),
    goHome() {
      setTimeout(() => {
        this.$router.push("/");
      }, 2000);
    },
  },
  created() {
    this.oidcSignInPopupCallback().catch((err) => {
      console.error(err); // Handle errors any way you want
    });
    setTimeout(() => {
      this.goHome();
    }, 1000);
  },
};
</script>
