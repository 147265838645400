import * as WrappedAccount from "./Accounts/WrappedAccount";

/**
 * 登陆账号
 * @param {Object} data 登陆信息
 * @returns Promise
 */
export function loginAsync(
  data = {
    UserName: "",
    Password: "",
    RememberMe: false,
  }
) {
  return WrappedAccount.loginAsync(data);
}

/**
 * 注销当前账号
 * @returns Promise
 */
export function logoutAsync() {
  return WrappedAccount.logoutAsync();
}

/**
 * 注册账号
 * @param {Object} data 注册信息
 * @returns Promise
 */
export function registerAsync(
  data = {
    username: "",
    password: "",
    remember_me: false,
  }
) {
  return WrappedAccount.registerAsync(data);
}

/**
 * 发送短信验证码
 * @param {Object} params 手机号等数据
 * @returns Promise
 */
export function sendMobileCodeAsync(
  params = {
    phoneNumber: "",
  }
) {
  return WrappedAccount.sendMobileCodeAsync(params);
}

/**
 * 获取图形验证码
 * @returns Promise
 */
export function getVerifyCodeAsync() {
  return WrappedAccount.getVerifyCodeAsync();
}

/**
 * 重设密码
 * @param {Object} params 重设密码数据
 * @returns Promise
 */
export function resetPasswordAsync(
  params = {
    username: "",
    password: "",
    verifyCode: "",
  }
) {
  return WrappedAccount.resetPasswordAsync(params);
}

/**
 * 获取用户信息
 * @returns Promise
 */
export function getUserInfoAsync() {
  return WrappedAccount.getUserInfoAsync();
}

/**
 * 切换公司
 * @param {Object} params 公司参数
 * @returns Promise
 */
export function switchCompanyAsync(params) {
  return WrappedAccount.switchCompanyAsync(params);
}

/**
 * 检查账号
 * @param {Object} params 注册信息
 * @returns Promise
 */
export function checkAccountAsync(params) {
  return WrappedAccount.checkAccountAsync(params);
}
