// 基础信息路由
const basicRoutes = [
  {
    path: "/info",
    component: () => import("@/views/Personal/Info.vue"),
    name: "info",
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/roles",
    component: () => import("@/views/Roles/Index.vue"),
    name: "roles",
    meta: {
      title: "角色权限",
    },
  },
  {
    path: "/roles/edit",
    component: () => import("@/views/Roles/EditRole.vue"),
    name: "editRole",
    meta: {
      title: "角色编辑",
    },
  },
  {
    path: "/companyManage",
    component: () => import("@/views/CompanyManage/Index.vue"),
    name: "companyManage",
    meta: {
      title: "公司管理",
    },
  },
];

export default basicRoutes;
