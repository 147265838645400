// Saas运营后台路由
const saasoperateRoutes = [
  {
    path: "/SaasAdmin/Companys",
    component: () => import("@/views/SaasAdmin/Companys/Index.vue"),
    name: "SaasAdminCompanys",
    meta: {
      title: "公司管理",
    },
  },
  {
    path: "/SaasAdmin/Users",
    component: () => import("@/views/SaasAdmin/Users/Index.vue"),
    name: "SaasAdminUsers",
    meta: {
      title: "用户管理",
    },
  },
  {
    path: "/SaasAdmin/Codes",
    component: () => import("@/views/SaasAdmin/Codes/Index.vue"),
    name: "SaasAdminCodes",
    meta: {
      title: "数据字典",
    },
  },
  {
    path: "/SaasAdmin/NotificationTpl",
    component: () => import("@/views/SaasAdmin/NotificationTpl/Index.vue"),
    name: "SaasAdminNotificationTpl",
    meta: {
      title: "通知模板",
    },
  },
];

export default saasoperateRoutes;
