<template>
  <router-view />
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/scss/index.scss";
</style>
