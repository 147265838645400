// 项目管理路由
const projectRoutes = [
  {
    path: "projectHome",
    name: "projectHome",
    component: () => import("@/views/ProjectManage/Home.vue"),
    meta: {
      title: "项目看板",
      isHome: true,
    },
  },
  {
    path: "projectTemplates",
    name: "projectTemplates",
    component: { render: (e) => e("router-view") },
    redirect: "/projectTemplates/index",
    meta: {
      title: "项目模板",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/ProjectTemplates/index.vue"),
        name: "projectTemplatesIndex",
        meta: {
          title: "项目模板管理",
        },
      },
      {
        path: "view",
        component: () =>
          import(
            "@/views/ProjectTemplates/TemplateDetails/TemplateDetails.vue"
          ),
        name: "TemplateDetails",
        meta: {
          title: "模板详情",
        },
      },
    ],
  },
  {
    path: "projectManage",
    name: "projectManage",
    component: { render: (e) => e("router-view") },
    redirect: "/projectManage/index",
    meta: {
      title: "项目管理",
    },
    children: [
      {
        path: "index",
        name: "projectManageIndex",
        component: () => import("@/views/ProjectManage/Index.vue"),
        meta: {
          title: "项目管理",
          default: true,
        },
      },
      {
        path: "add",
        component: () => import("@/views/ProjectManage/ProjectAdd.vue"),
        name: "projectManage/add",
        meta: {
          title: "创建项目",
        },
      },
      {
        path: "edit/:id/:tab",
        component: () => import("@/views/ProjectManage/ProjectEdit.vue"),
        name: "projectManage/edit",
        meta: {
          title: "编辑项目",
        },
      },
      {
        path: "task/:id/:projectId",
        component: () => import("@/views/TaskManage/TaskDetail.vue"),
        name: "projectManage/task",
        meta: {
          title: "任务详情",
        },
      },
    ],
  },
];

export default projectRoutes;
