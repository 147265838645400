/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import getters from "./getters";
import account from "./modules/account";
import oidc from "./modules/oidc";
import oidcGetters from "./modules/oidc-getters";
import secureLs from "secure-ls";
// import { vuexOidcCreateStoreModule } from "vuex-oidc";

const ls = new secureLs({
  encodingType: "aes",
  isCompression: true,
  encryptionSecret: "helloYgc@2021",
});
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    account,
    oidcStore: oidc,
  },
  actions,
  getters: {
    ...getters,
    ...oidcGetters,
  },
  plugins: [
    createPersistedState({
      storage: {
        // getItem: (key) => sessionStorage.getItem(key),
        // setItem: (key, value) => sessionStorage.setItem(key, value),
        // removeItem: (key) => sessionStorage.removeItem(key),
        getItem: (key) => {
          try {
            return ls.get(key);
          } catch (e) {
            ls.clear();
            return ls.get(key);
          }
        },
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
export default store;
