/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// import loginRoutes from "./modules/login";
import oidc from "./modules/oidc";
import basicRoutes from "./modules/basic";
import projectRoutes from "./modules/project";
import productionRoutes from "./modules/production";
import resourceRoutes from "./modules/resource";
import taskRoutes from "./modules/task";
import saasoperateRoutes from "./modules/saasAdmin";
import salesRoutes from "./modules/sales";
import purchaseRoutes from "./modules/purchase";
import storageRoutes from "./modules/storage";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import titleMiddleware from "./titleMiddleware";
import hardwareMiddlware from "./hardwareMiddleware";
Vue.use(VueRouter);
const allRouteList = [
  ...oidc,
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("@/views/Home/Welcome.vue"),
    meta: {
      title: "欢迎使用智能硬件研发平台",
    },
  },
  {
    path: "/initCompany",
    name: "initCompany",
    component: () => import("@/views/Home/InitCompany.vue"),
    meta: {
      title: "欢迎加入",
    },
  },
  {
    path: "/",
    name: "homeIndex",
    component: () => import("@/layout/Layout.vue"),
    redirect: "/projectHome",
    children: [
      {
        path: "home",
        name: "home",
        meta: {
          title: "首页",
          isHome: true,
        },
      },
      ...basicRoutes,
      ...projectRoutes,
      ...productionRoutes,
      ...resourceRoutes,
      ...taskRoutes,
      ...saasoperateRoutes,
      ...salesRoutes,
      ...purchaseRoutes,
      ...storageRoutes,
    ],
  },
  {
    path: "*",
    component: () => import("@/views/Redirect/Error.vue"),
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: allRouteList,
});

const oidcMiddleware = vuexOidcCreateRouterMiddleware(store, "oidcStore");
router.beforeEach(oidcMiddleware);
router.beforeEach(titleMiddleware);
router.beforeEach(hardwareMiddlware);
router.onError((error) => {
  console.log(error.message);
});
export const routeList = allRouteList;
export default router;
