/* eslint-disable no-unused-vars */
import store from "@/store";
import notification from "ant-design-vue/es/notification";
const dispatchRoute = function (from, to, next) {
  if (to.meta && to.meta.isHome) {
    const userInfo = store.getters.getUserInfo;
    const currentCompany = userInfo.currentCompany;
    if (currentCompany && currentCompany.id) {
      if (from.name === "login") {
        // 从登陆跳转到登陆前页面
        if (
          from.query &&
          from.query.redirect &&
          from.query.redirect != to.path
        ) {
          return next({ path: from.query.redirect });
        }
      }
    } else {
      return next({ path: "/initCompany" });
    }
  }
  return next();
};
export default (to, from, next) => {
  // 2021年7月21日 添加 切换公司后，如果没有当前公司也要重新初始化
  const userInfo = store.getters.getUserInfo;
  const oidcUser = store.getters["oidcStore/oidcUser"];
  const accessToken = store.getters.getToken;
  if (!accessToken) return next();
  if (
    !userInfo.name ||
    (!userInfo.currentCompany && from.path === "/") ||
    userInfo.email != oidcUser.email
  ) {
    store
      .dispatch("loadUserInfo")
      .then((res) => {
        dispatchRoute(from, to, next);
      })
      .catch((e) => {
        notification.error({
          message: "错误",
          description: "请求用户信息失败，请重试",
        });
        store.dispatch("logout", true);
      });
  } else {
    dispatchRoute(from, to, next);
  }
};
