/* eslint-disable no-unused-vars */
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import config from "@/config";
import { WebStorageStateStore } from "oidc-client";
import secureLs from "secure-ls";
const ls = new secureLs({
  encodingType: "aes",
  isCompression: true,
  encryptionSecret: "2021@zggcd-100",
});
const oidcSettings = config.oidc;
// NOTE: If you want to customize how tokens are stored you can use WebStorageStateStore from oidc-client (see bellow)
// Default storage is window.localStorage
// import { WebStorageStateStore } from 'oidc-client'
const oidcStore = vuexOidcCreateStoreModule(
  {
    ...oidcSettings,
    userStore: new WebStorageStateStore({
      store: {
        clear: () => ls.clear(),
        getItem: (key) => {
          try {
            return ls.get(key);
          } catch (e) {
            ls.clear();
            return ls.get(key);
          }
        },
        key: (index) => ls.getAllKeys()[index],
        removeItem: (key) => ls.remove(key),
        setItem: (key, value) => ls.set(key, value),
      },
    }),
  },
  // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
  // spread your oidcSettings and define a userStore of your choice
  // {
  //   ...oidcSettings,
  //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
  // },
  // Optional OIDC store settings
  { namespaced: true, dispatchEventsOnWindow: true },
  // Optional OIDC event listeners
  {
    userLoaded: (user) => console.log("OIDC user is loaded:", user),
    userUnloaded: () => console.log("OIDC user is unloaded"),
    accessTokenExpiring: () => console.log("Access token will expire"),
    accessTokenExpired: () => console.log("Access token did expire"),
    silentRenewError: () => console.log("OIDC user is unloaded"),
    userSignedOut: () => {
      // window.location.href="/log"
      console.log("OIDC user is signed out");
    },
    oidcError: (payload) => console.log("OIDC error", payload),
  }
);

export default oidcStore;
