<template>
  <div>
    <h1>验证失败，请关闭浏览器后重试！</h1>
  </div>
</template>

<script>
export default {
  name: "OidcCallbackError",
};
</script>
