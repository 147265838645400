//销售管理路由
const salesRoutes = [
  {
    path: "SalesManage",
    component: { render: (e) => e("router-view") },
    redirect: "/SalesManage/index",
    name: "SalesManage",
    meta: {
      title: "销售订单",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/SalesManage/Index.vue"),
        name: "SalesManageIndex",
        meta: {
          title: "订单管理",
        },
      },
      {
        path: "detail/:saleOrderId",
        component: () => import("@/views/SalesManage/Detail.vue"),
        name: "SalesManageDetail",
        meta: {
          title: "新增订单",
        },
      },
      {
        path: "new",
        component: () => import("@/views/SalesManage/New.vue"),
        name: "SalesManageNew",
        meta: {
          title: "新增订单",
        },
      },
    ],
  },
  {
    path: "CustomerManage",
    name: "CustomerManage",
    component: { render: (e) => e("router-view") },
    redirect: "/CustomerManage/index",
    meta: {
      title: "客户管理",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/CustomerManage/Index.vue"),
        name: "CustomerManageIndex",
        meta: {
          title: "客户列表",
        },
      },
      {
        path: "contactPerson",
        component: () => import("@/views/CustomerManage/ContactPerson.vue"),
        name: "CustomerManageContactPerson",
        meta: {
          title: "联系人列表",
        },
      },
      {
        path: "selectRegion",
        component: () =>
          import("@/views/CustomerManage/components/SelectRegions.vue"),
        name: "SelectRegions",
        meta: {
          title: "选择区域",
        },
      },
    ],
  },
];

export default salesRoutes;
