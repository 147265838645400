const env = { ...process.env };
const oidcConfig = {
  client_secret: "ygc2021",
  authority: env.VUE_APP_OICD_AUTHORITY,
  // authority: "http://localhost:5000",
  client_id: "hardware-saas-client",
  redirect_uri: env.VUE_APP_OICD_REDIRECTURI,
  post_logout_redirect_uri: env.VUE_APP_OICD_LOGOUT_URL,
  popup_redirect_uri: env.VUE_APP_OICD_LOGOUT_URL,
  response_type: "id_token token",
  load_user_info: true,
  // response_type: "code",
  scope: env.VUE_APP_OICD_SCOPE || "openid profile default-api",
  // scope: 'openid api',
  show_debug_information: true,
  // prompt: "none",
  // SRM SSO 暂时不支持静默刷新
  silent_redirect_uri: env.VUE_APP_OICD_SILENT_URL,
  automaticSilentRenew: true, // If true oidc-client will try to renew your token when it is about to expire
  automaticSilentSignin: false, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true
};

export default oidcConfig;
