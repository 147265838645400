import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/antd";
import "ant-design-vue/dist/antd.css";
import PageTitle from "@/components/PageTitle";
import NotificationHubPlugin from "@/components/Hubs/NotificationHubPlugin";
import bootstrap from "@/core/bootstrap";

// import ElementUI from "element-ui"
// import "element-ui/lib/theme-chalk/index.css"
// Vue.use(ElementUI)

Vue.use(NotificationHubPlugin);
Vue.component("page-title", PageTitle);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  created: bootstrap,
}).$mount("#app");
