<template>
  <div></div>
</template>

<script>
import { vuexOidcProcessSilentSignInCallback } from "vuex-oidc";

export default {
  created() {
    vuexOidcProcessSilentSignInCallback();
  },
};
</script>

<style></style>
