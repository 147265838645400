<template>
  <a-card>
    <div style="text-align: center">
      <h1>正在读取您的信息...</h1>
      <a-spin :spinnig="true" />
    </div>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "OidcCallback",
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback"]),
  },
  created() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath);
      })
      .catch((err) => {
        console.error(err);
        this.$router.push("/signin-oidc-error"); // Handle errors any way you want
      });
  },
};
</script>
