import HubBase from "./HubBase";

/**
 * 通知中心
 */
export default class NotificationHub extends HubBase {
  constructor() {
    super("notification hub", "/hubs/common/notification");
    /**
     * 获取当前特定状态的通知
     * @param {Any} readState 通知状态 0:已读 1:未读
     * @param {Number} maxResultCount 最大记录数，默认 10
     */
    this.getNotificationAsync = function (readState, maxResultCount) {
      return this.hub.invoke(
        "GetNotification",
        readState || 0,
        maxResultCount || 10
      );
    };
    /**
     * 获取当前待查阅的通知
     * @param {Number} maxResultCount 最大记录数，默认 10
     */
    this.getViewNotificationAsync = function (maxResultCount) {
      return this.hub.invoke(
        "GetUserViewNotificationsPage",
        maxResultCount || 10
      );
    };
    /**
     * 变更通知状态
     * @param {Number} readState 通知状态 0:已读 1:未读
     * @param {String} id 通知Id
     */
    this.changeStateAsync = function (id, readState) {
      return this.hub.invoke("ChangeState", id, readState);
    };

    /**
     * 当接收到消息时
     * @param {Function} handler  notification
     */
    this.onGetNotification = function (handler) {
      this.hub.on("getNotification", handler);
    };

    this.afterInit = function () {
      this.hub.on("getNotification", this.triggerChange);
    };
  }
}
