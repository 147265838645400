//销售管理路由
const purchaseRoutes = [
  {
    path: "PurchaseManage",
    component: { render: (e) => e("router-view") },
    redirect: "/PurchaseManage/index",
    name: "PurchaseManage",
    meta: {
      title: "采购需求管理",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/PurchaseManage/Index.vue"),
        name: "GoodsManageIndex",
        meta: {
          title: "商品管理",
        },
      },
      {
        path: "detail/:goodsId/:materialItemId",
        component: () => import("@/views/PurchaseManage/Detail.vue"),
        name: "GoodsManageDetail",
        meta: {
          title: "商品详情",
        },
        props: true,
      },
    ],
  },
];

export default purchaseRoutes;
