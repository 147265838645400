//仓储管理路由
const storageRoutes = [
    {
        path: "Storage",
        component: { render: (e) => e("router-view") },
        redirect: "Storage",
        name: "Storage",
        meta: {
            title: "出入库管理",
        },
        children:[
            {
                path: "InboundList",
                component: () => import("@/views/Storage/InboundList.vue"),
                name: "InboundList",
                meta: {
                  title: "入库列表",
                },
            },
            {
                path: "AddInbound",
                component: () => import("@/views/Storage/AddInbound.vue"),
                name: "AddInbound",
                meta: {
                  title: "新增入库单",
                },
            },
            {
                path: "Outboundlist",
                component: () => import("@/views/Storage/Outboundlist.vue"),
                name: "Outboundlist",
                meta: {
                  title: "出库列表",
                },
            },
            {
                path: "AddOutbound",
                component: () => import("@/views/Storage/AddOutbound.vue"),
                name: "AddOutbound",
                meta: {
                  title: "新增出库单",
                },
            },
            {
                path: "InventoryInquiry",
                component: () => import("@/views/Storage/InventoryInquiry.vue"),
                name: "InventoryInquiry",
                meta: {
                  title: "库存查询",
                },
            },
            {
              path: "StoreConfig",
              component: () => import("@/views/Storage/StoreConfig.vue"),
              name: "StoreConfig",
              meta: {
                title: "仓库配置",
              },
          },
          {
            path: "InventoryDistributed",
            component: () => import("@/views/Storage/InventoryDistributed.vue"),
            name: "InventoryDistributed",
            meta: {
              title: "库存分布明细",
            },
        },
        ]
    },
    
];

export default storageRoutes;